<template>
	<div class="line-chart">
		<canvas ref="canvas"></canvas>
	</div>
</template>

<script>
import { Line } from "vue-chartjs";

export default {
    extends: Line,
    async mounted() {
		this.setup();
    },
	methods: {
		setup() {
			let data = {
				labels: [ "", "", "", "", "", "", "", "" ],
				datasets: [
					{
						label: "Clichs: 15",
						data: [ 1, 5, 4, 8, 6, 12, 9, 15],
						backgroundColor: [
							"rgba(93, 52, 206, 0.2)",
							// "rgba(54, 162, 235, 0.2)",
							// "rgba(255, 206, 86, 0.2)",
							// "rgba(75, 192, 192, 0.2)",
							// "rgba(153, 102, 255, 0.2)",
							// "rgba(255, 159, 64, 0.2)",
						],
						borderColor: [
							"rgba(93, 52, 206, 1)",
							// "rgba(54, 162, 235, 1)",
							// "rgba(255, 206, 86, 1)",
							// "rgba(75, 192, 192, 1)",
							// "rgba(153, 102, 255, 1)",
							// "rgba(255, 159, 64, 1)",
						],
						borderWidth: 1,
					},
					{
						label: "SignUps: 12",
						data: [ 1, 4, 3.5, 6, 5, 9, 8, 12],
						backgroundColor: [
							"rgba(255, 0, 0, 0.2)",
							// "rgba(54, 162, 235, 0.2)",
							// "rgba(255, 206, 86, 0.2)",
							// "rgba(75, 192, 192, 0.2)",
							// "rgba(153, 102, 255, 0.2)",
							// "rgba(255, 159, 64, 0.2)",
						],
						borderColor: [
							"rgba(255, 0, 0, 1)",
							// "rgba(54, 162, 235, 1)",
							// "rgba(255, 206, 86, 1)",
							// "rgba(75, 192, 192, 1)",
							// "rgba(153, 102, 255, 1)",
							// "rgba(255, 159, 64, 1)",
						],
						borderWidth: 1,
					},
					{
						label: "Subscriptions: 8",
						data: [ 1, 3.5, 3, 4, 4.5, 7, 6, 8],
						backgroundColor: [
							"rgba(176, 10, 255, 0.2)",
							// "rgba(54, 162, 235, 0.2)",
							// "rgba(255, 206, 86, 0.2)",
							// "rgba(75, 192, 192, 0.2)",
							// "rgba(153, 102, 255, 0.2)",
							// "rgba(255, 159, 64, 0.2)",
						],
						borderColor: [
							"rgba(176, 10, 255, 1)",
							// "rgba(54, 162, 235, 1)",
							// "rgba(255, 206, 86, 1)",
							// "rgba(75, 192, 192, 1)",
							// "rgba(153, 102, 255, 1)",
							// "rgba(255, 159, 64, 1)",
						],
						borderWidth: 1,
					},
				],
			};

			let options = {
				scales: {
					yAxes: [
						{
				// 			stacked: true,
							ticks: {
								beginAtZero: true,
								min: 0,
								max: 20
							},
						}
					],
				// 	xAxes: [
				// 		{
				// 			stacked: true,
				// 			ticks: {
				// 				beginAtZero: true,
				// 				categoryPercentage: 0.5,
				// 				barPercentage: 1,
				// 			},
				// 		}
				// 	],
				},
				// responsive: true,
				// maintainAspectRatio: false,
			}
			this.renderChart(data, options);
		}
	}
};
</script>

<style lang="scss" scoped>

</style>